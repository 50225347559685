* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --color-131921: #131921;
  --color-3b4149: #3b4149;
  --color-febd69: #febd69;
  --color-232f3e: #232f3e;
  --color-bf4800: #bf4800;
  --color-f5f5f7: #f5f5f7;
  --color-ededed: #ededed;
  --color-777777: #777777;
  --color-1c1c1b: #1c1c1b;
  --color-e52e2e: #e52e2e;
}
.section-heading {
  font-size: 26px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 30px;
}
.card-wrapper {
  background-color: white;
  box-shadow: 0 0 10px #0000001a;
  padding: 15px;
}
body {
  font-family: "Rubik", sans-serif;
}
a {
  text-decoration: none;
  display: inline-block;
}
.form-control:focus {
  box-shadow: none;
  border-color: inherit;
}

.gap-10 {
  gap: 10px;
}
.gap-15 {
  gap: 15px;
}
.gap-30 {
  gap: 30px;
}

.button {
  background-color: var(--color-232f3e);
  color: white;
  font-size: 14px;
  padding: 13px 33px;
  border-radius: 25px;
}
.button:hover {
  background-color: var(--color-febd69);
  color: #131921;
  cursor: pointer;
}

::-webkit-scrollbar {
  display: none;
}
.error {
  color: var(--color-e52e2e);
  font-size: 12px;
}
.images-icons {
  border: 0;
  background: transparent;
  background-color: transparent;
  cursor: pointer;
}
