footer {
    background-color: var(--color-232f3e);
  }

footer:not(:first-child) {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
footer .input-group-text {
    background-color: var(--color-232f3e);
    color: white;
    padding: 8px 17px;
  }   
.f-1{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
.container-b{
      max-width: 1400px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 15px;
      padding-right: 15px;
}
.row-b{
  display: flex;
  flex-wrap: wrap;
  margin-left: 50px;
  margin-right: 50px;
}
.coloum-b{
  width: 100%;
}
.p-1{
text-align: center;
margin-bottom: 0;
color:white
}
.coloum-a{
  width: 41.66667%;
}
.coloum-c{
  width: 58.33333%;
}
.p-a{
  margin-bottom: 0;
  color: white;
}

.row-a{
  display: flex;
  flex-wrap: wrap;
  margin-left: 50px;
  margin-right: 50px;
}
/* Input group container */
.input-group-2 {
  position: relative;
  display: flex;
  align-items: center;
}

/* Input field */
.form-control-2 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  width: 100%;
  height: 50px;
  padding: 0.475rem 0.75rem;
  font-size: 1rem;
  color: #495057;
  border-radius: 0.25rem;
}
.form-control-2:focus{
  box-shadow: none;
  border-color: inherit;
}
/* Input group text */
.input-group-text-2 {
  padding-top: 0.5rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  width: 12%;
  height: 46.5px;
  border: none;
  border: 0.5px solid white;
  margin-left: -6px;
  display: flex;
  align-items: center;
  padding: 0.623rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  border-radius: 0.25rem;
  margin-right: 150px;
}
/* Form end Here */
 .f-2{
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.container-10{
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.row-10{
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}
.coloum-20{
  float: left;
  width: 333.3px;
}
.coloum-30{
  float: left;
  width: 25%;
}
.coloum-40{
  float: left;
  width: 25%;
}
.coloum-50{
  float: left;
  width: 16.6667%;
}
.links-20{
  color: white;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 4px;
  margin-left: 21px;
}
.footer-links{
  display: flex;
  flex-direction: column;
}
.social-10{
  font-size: 1.5rem;
  line-height: 1.2;
}
@media (max-width: 600px) {
    .f-1,.footer-top-data,
    .coloum-c {
      display: none;
    }
    .footer-links {
      display: flex;
      flex-direction: column;
    }
    .coloum-30,
    .coloum-40,
    .coloum-50 {
      width: 100%;
      display: block;
      margin-bottom: 16px;
    } 
    .accordion-icon {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-evenly;
      gap: 4px;
    }
    .accordion-content {
      display: none;
    }
  
    .accordion-content.active {
      display: block;
    }
    .bs-icon{
      font-size: 20px;
      font-weight: 1000;
    }
    .accordion-icon svg:first-child {
      order: -1;
    }
  }
 
  @media (min-width: 1024px) {
    .accordion-icon {
      display: none;
    }
  }
  