.breadcrumb {
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: white;
    border-radius: 0.25rem;
    padding-top: 1rem;
   padding-bottom: 1rem;
   margin-bottom: 0;
}
  
.breadcrumb-item {
    display: inline-block;
}
  
.breadcrumb-item + .breadcrumb-item::before {
    content: "›";
    padding: 0 0.5rem;
    color: #6c757d;
}
  
.breadcrumb-item.active {
    color: #6c757d;
}

.bread-container-01{
  width: 100%;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}
.bread-row-01{
    display: flex;
    flex-wrap: wrap;
    margin-right: 15px;
    margin-left:  15px;
}
.bread-coloum-01{
    width: 100%;
}
.bread-link-01{
    color: #343a40;
}
.bread-coloum-01 > p {
    text-align: center;
    margin-bottom: 0;
}