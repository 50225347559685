
.login-wrapper {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.login-container-01 {
  max-width: 1320px;
  margin-right: auto;
  margin-left: auto;
} 

.login-row-01 {
  display: flex;
  flex-wrap: wrap;
  margin-right: 50px;
  margin-left: 50px;
}

.login-h3-01 {
  text-align: center;
  margin-bottom: 1rem;
}

.login-coloum-01 {
  width: 100%;
}

.form-control {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #f7f7f7;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 10px;
  border-color: transparent;
}

/* login card */
.auth-card {
  padding: 20px;
  background-color: white;
  width: 500px;
  margin: 30px auto;
  border-radius: 10px;
}

.auth-card h3 {
  font-size: 19px;
  font-weight: 500;
  color: var(--color-777777);
}

.login-div-01 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 15px;
  margin: 10px;
  margin-top: 1.5rem;
}

.login-div-02 {
  margin-top: 1rem;
}

.signup {
  background-color: #febd69;
  border: none;
}

.signup:hover {
  background-color: var(--color-232f3e);
  color: white;
}

.auth-card a {
  color: var(--color-1c1c1b);
  font-size: 14px;
  font-weight: 400;
}



/* Mobile */
@media (max-width: 767px) {
    .login-coloum-01{
        width: 100%;
    }
    .auth-card {
      width: 100%;
      margin: 0;
    }
    .login-row-01{
        margin-left: 50px;
    }
    .form-control{
        max-width: 70%;
    }
  }
  
  /* Tablet */
  @media (min-width: 768px) and (max-width: 991px) {
    .auth-card {
      width: 80%;
    }
  }
  
  /* Desktop */
  @media (min-width: 992px) {
    .auth-card {
      width: 500px;
      margin: 30px auto;
    }
  }