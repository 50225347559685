/* PaymentModal.css */

.payment-modal-overlay {
  position: relative;
  top: -330px;
  left: 323px;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.6); */
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 100%;
}

.payment-modal h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.payment-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.payment-options button {
  background-color: #232f3e;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.payment-options button:hover {
  background-color: #febd69;
}

.close-button {
  background-color: #f44336;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #d32f2f;
}
