.home-wrapper-02{
    background-color: #f5f5f7;
}
.store-container-01{
    max-width: 1320px;
    margin-right: auto;
    margin-left: auto;
}
.store-row-01{
    display: flex;
  flex-wrap: wrap;
  margin-left: 50px;
  margin-right: 50px;
}
.store-coloum-01{
    width: 25%;
    float: left;
}

.filter-card{
    background-color: white;
    border-radius: 10px;
    padding: 15px 15px 25px;
    margin-bottom: 1rem; 
    margin-left:-14px;
    margin-right: 25px; 
}
.filter-card-filter{
    background-color: white;
    border-radius: 10px;
    padding: 15px 15px 25px;
    margin-bottom: 1rem; 
    margin-left:-14px;
    margin-right: 25px; 
}
.filter-title{
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: var(--color-1c1c1b);
    margin-bottom: 20px;
}
.filter-card ul{
    list-style: none;
    font-size: 13px;
    line-height: 30px;
    color: var(--color-777777);   
    cursor: pointer;
}
.sub-title{
    font-size: 14px;
    font-weight: 600;
    color:var(--color-1c1c1b);
    margin-bottom: 15px;
    margin-top: 20px;
}
.form-check {
    position: relative;
    display: block;
    margin-bottom: 0.5rem;
}
.form-check-input:focus{
 box-shadow:none;
 border-color: inherit;
 border-radius: 0%;   
} 
.form-check-input:checked{
    background-color: #febd69;
    position: absolute;
    margin-top: 0.5rem;
    margin-left: 0.1rem;
}  
.form-check-label {
    margin-left: 1.2rem;
}
.filter-card .form-check-label {
    list-style: none;
    font-size: 15px;
    line-height: 30px;
    color: var(--color-777777);   
    cursor: pointer;
}

.form-floating {
    position: relative;
}
  
.form-floating label {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    padding: 0.5rem;
}

.form-floating input {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 4px 12px;
    background-color: #FFFFFF;
}
.colors{
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-left: 0;
}
.colors li{
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
}

/* product tage section */
.product-tags{
    display: flex;
    flex-wrap: wrap;
    align-items: center;                               
    gap: 10px ;
}
.store-badge-01{
    background-color:#f8f9fa;
    color:  #6c757d;
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.375rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    text-transform: capitalize;
    padding-right: 1rem;
}

/* Random products Section */
.random-products{
    display: flex;
    margin-bottom: 1rem;
}
.random-products h5{
    font-size: 14px;
    margin-bottom: 8px;
}
.random-products:first-child{
    border-bottom: 1px solid #ebebeb;
}
.store-random-img{
    max-width: 100%;
    height: auto;
}
.store-div-001{
    width: 50%;
}
.store-div-002{
    width: 50%;
}

/* now column 2 part */

.store-coloum-02{
    width: 75%;
    float: left;
}
.store-div-003{
    display: flex;
    justify-content:space-between;
    align-items: center;
}
.store-div-004{
    display: flex;
    align-items: center;
    gap: 10;
}
.store-p-001{
    margin-bottom: 0;
    display: block;
    width: 100px;
}
.filter-sort-grid{
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 1.5rem;
}
.store-div-005{
    display: flex;
    align-items: center;
    gap: 10px;
}
.total-products{
    margin-bottom: 0;
}
.grid img {
    width: 35px;
    height: 35px;
    background-color: #f2f2f2;
    padding: 8px;
    border-radius: 3px;
    cursor: pointer;
}
.grid img:hover{
    background-color: var(--color-febd69);
    font: white;
}

.store-img-001{
    display: block;
    max-width: 100%;
    height: auto;
}
.products-list{
    padding-bottom: 3rem;
}
@media (max-width: 600px) {
    .store-row-01 {
      margin-left: 10px;
      margin-right: 10px;
    }
    .store-coloum-01,
    .store-coloum-02 {
      width: 35%;
      float: none;
    }
    .filter-card-filter ,.form-floating{
        display: none;
    }
    .store-p-001 , .title {
        display: none;
    }
    .total-products{
        display: none;
    }
    .store-img-001{
        display: none;
    }
    .filter-sort-grid{
        display: none;
    }
  }
  
  /* CSS for medium screens */
  @media (min-width: 600px) {
    .store-row-01 {
      margin-left: 20px;
      margin-right: 20px;
    }
    .store-coloum-01 {
      width: 30%;
    }
    .store-coloum-02 {
      width: 70%;
    }
  }